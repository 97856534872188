import React, { useRef, useEffect } from "react"

import Layout from "../../components/layout"
import HeroTwo from "../../components/Hero/HeroTwo"
import Overview from "../../components/CaseStudy/Overview"
import { SEO } from "../../components/seo"
import TitleContent from "../../components/TitleContent/TitleContent"
import Hello from "../../components/Hello/Hello"
import Newsletter from "../../components/Form/Newsletter"
import ContentImage from "../../components/ContentImage/ContentImage"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import StaticTestimonial from "../../components/Testimonial/StaticTesimonial"
import ShowCase from "../../components/CaseStudy/CaseStudyShowCase"
import { caseStudies } from "."
import ShowcaseSlider from "../../components/ShowcaseSlider"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import CelerityBanner from "../../components/CaseStudy/CelerityBanner"
import StickySLide from "../../components/StickySlide"
if (typeof window !== undefined) {
    gsap.registerPlugin(ScrollTrigger)
}
const techs = [
    { src: "/case-study/next.svg" },
    { src: "/case-study/prismic.svg" },
    { src: "/case-study/tailwind.svg" },
    { src: "/case-study/expedia.svg" }
]

const heroData = {
    preheader: "Ski.com",
    title: "From Powder to Pixels – Launching Ski.com",
    fullWidth: true
}
const overview = {
    overview:
        "Ski.com, the largest provider of ski holiday packages globally, partnered with Saigon Digital to create a modern, user-friendly website that would position them as the digital leader in the ski holiday industry. Our mission was to design a captivating online presence that enhanced user experience, improved SEO, and optimised performance across all devices.",
    services: [
        "Rebranding",
        "Web Development",
        "SEO Optimisation",
        "CMS integration"
    ],
    linkUrl: "https://www.ski.com/"
}
const lhScores = [
    {
        title: "Performance",
        score: 100
    },
    {
        title: "Accessibility",
        score: 96
    },
    {
        title: "Best Practices",
        score: 96
    },
    {
        title: "SEO",
        score: 100
    }
]
const SkiCom = ({ location }) => {
    const context = {
        pageName: "SD | Case studies - Ski.com",
        pageUri: location.href
    }

    return (
        <Layout context={context}>
            <HeroTwo props={heroData} />
            <Overview {...overview} />
            <StaticImage
                src={"../../../static/case-study/ski/Ski-Top-banner.png"}
                alt="ski.com top banner"
                objectFit="cover"
                className="mt-20 h-full w-full lg:mt-24"
            />
            <TitleContent
                title="Designing a Modern Online Experience for Ski.com "
                className="py-14 lg:py-24 "
            >
                <p className="mb-16 lg:mb-20">
                    Our collaboration with Ski.com was a transformative journey
                    to build a digital platform that embodied their
                    market-leading status in ski holiday bookings.
                    <br />
                    At the heart of this project was the JAMstack architecture,
                    an innovative approach that separates the front-end and
                    back-end, providing enhanced flexibility and performance.
                    With the use of Prismic CMS and Next.js, we delivered a
                    seamless and intuitive content management experience,
                    allowing Ski.com’s marketing team to easily manage their
                    website and deliver content swiftly.
                </p>
                <h3 className="mt-6 text-lg font-semibold">
                    Technologies used:
                </h3>
                <div class="mt-6 grid grid-cols-2 gap-4 md:gap-4 lg:grid-cols-3 lg:gap-[30px]">
                    {techs.map(({ src }, index) => {
                        return (
                            <div class="group grid max-h-[74px] min-h-[74px] place-items-center border px-6 py-2 transition-all duration-300 hover:shadow-light_shadow">
                                <img
                                    src={src}
                                    className="max-w-full object-cover grayscale transition-all duration-300 group-hover:grayscale-0"
                                    alt="logos"
                                />
                            </div>
                        )
                    })}
                </div>
            </TitleContent>
            <Hello
                link="https://saigon.digital/blog/advancing-web-security-jamstack-versus-traditional-website-development"
                title="Optimise User-Experience with Google Lighthouse"
                message={"If you want to get started manage with JAMstack, "}
                messageLinkTitle={
                    "check out our JAMstack Versus Traditional Website Development!"
                }
                lhScores={lhScores}
            >
                <p className="mb-4">
                    Google Lighthouse is an open-source tool for website
                    performance, auditing pages for performance, accessibility,
                    SEO, Progressive Web App and Best Practices.
                </p>
                <p className="mb-4">
                    Using Google Lighthouse as a benchmark, we make sure that
                    Ski.com's website is performing flawlessly with excellent
                    scores.
                </p>
                <p>
                    Here is the performance results:{" "}
                    <a
                        href="/ski.com-report.html"
                        target="_blank"
                        download
                        className="font-semibold text-primary-color underline"
                    >
                        Google Lighthouse Report
                    </a>
                </p>
            </Hello>
            <TitleContent title="The Challenge" className={"py-24 pt-28"}>
                <p>
                    {" "}
                    Ski.com sought a complete revamp of their legacy website,
                    aiming to modernise the design and create a fast,
                    SEO-optimised platform that reflected their premium brand.
                    They also needed a solution that would seamlessly integrate
                    with their Expedia booking engine, allowing users to book
                    ski holidays effortlessly.
                </p>
            </TitleContent>

            <ShowcaseSlider
                slides={slides}
                className={" aspect-[1440/360] h-auto !min-h-0 w-full"}
            />

            <TitleContent title="The Solutions" className={"my-28"}>
                <p className="text-base text-[#1D2333]">
                    To meet these objectives, we embraced the JAMstack
                    methodology, utilising Next.js for its fast, SEO-friendly
                    capabilities. Prismic CMS was selected to provide a
                    flexible, content-driven platform, empowering Ski.com’s
                    marketing team with a customisable and user-friendly
                    management system. We further enriched the design with
                    Tailwind CSS and GSAP animations, creating a visually
                    engaging, responsive website that performs smoothly across
                    all devices. Additionally, the integration of Ski.com’s
                    Expedia booking engine was streamlined, ensuring a seamless
                    and efficient user journey from browsing to booking.
                </p>
            </TitleContent>
            <div className="container mb-24 grid gap-x-[30px] gap-y-10 md:grid-cols-2 lg:mb-24 lg:gap-x-24">
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:ml-5 ">
                    <img
                        src="/case-study/ski/Ski.com-2col-1.png"
                        className="h-full w-full duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
                <div className="m-0 aspect-[475/356] w-full overflow-hidden lg:mr-5 ">
                    <img
                        src="/case-study/ski/Ski.com-2col-2.png"
                        className="h-full w-full object-cover transition-transform duration-1000 hover:scale-105"
                        alt="case study"
                        objectFit="cover"
                    />
                </div>
            </div>
            <Newsletter />
            <TitleContent title="Outcomes" className="mt-20 lg:mt-28">
                <p>
                    The newly designed website enhances the Ski.com brand,
                    delivering a fast, responsive, and user-friendly experience
                    that positions them as the digital leader in ski holiday
                    bookings. By integrating a modern tech stack, the site is
                    now future-proof, scalable, and delivers high performance
                    across all devices.
                </p>
            </TitleContent>

            <StickySLide>
                <ContentImage
                    className="lg:opacity-100"
                    img={"/case-study/ski/ski.com-outcome-1.png"}
                >
                    <h2 className="mb-4 text-2xl font-semibold">
                        Elevating the Ski.com Brand
                    </h2>

                    <p>
                        Ski.com’s digital platform now perfectly aligns with
                        their premium brand identity. The website delivers a
                        sleek and engaging user experience, enhancing customer
                        satisfaction and boosting brand loyalty. With a
                        bilingual interface for both English and
                        Vietnamese-speaking users, the site’s global reach has
                        been expanded.
                    </p>
                </ContentImage>
                <ContentImage img={"/case-study/ski/ski.com-outcome-2.png"}>
                    <h2 className="mb-4 text-2xl font-semibold">
                        Performance and SEO Benefits
                    </h2>

                    <p className="text-[#1D2333]">
                        The combination of Next.js, Prismic, and our advanced
                        SEO strategies has significantly improved the website’s
                        visibility on search engines. The enhanced load speeds,
                        server-side rendering, and static generation have
                        optimised user experience and search engine rankings,
                        contributing to a wider audience reach.
                    </p>
                </ContentImage>
                <ContentImage img={"/case-study/ski/ski.com-outcome-3.png"}>
                    <h2 className="mb-4 text-2xl font-semibold">
                        Modern Tech Stack
                    </h2>

                    <p>
                        The migration to a modern tech stack positions Ski.com
                        ahead of competitors, with a site that can easily scale,
                        update, and deliver the best digital experiences for ski
                        vacation bookings.
                    </p>
                </ContentImage>
            </StickySLide>
            <TitleContent
                title={"The Results - The Collaborative Journey Continues"}
                className={"mb-28"}
            >
                <p>
                    Our work with Ski.com highlights the power of JAMstack
                    development in merging traditional brand values with
                    cutting-edge digital solutions. The newly designed website
                    reflects Ski.com’s market-leading status and delivers an
                    optimal digital experience, increasing user engagement and
                    streamlining the booking process.
                </p>
                <p className="mt-4">
                    Through our collaboration, Ski.com now has a scalable,
                    modern website that supports their continued growth and
                    cements their position as the digital leader in ski holiday
                    bookings..
                </p>
            </TitleContent>

            <div className="relative z-10 flex min-h-[40vh] flex-col items-center justify-center py-20 text-center  lg:py-20">
                <img
                    src="/case-study/ski/Result.png"
                    className="absolute inset-0 z-0 h-[calc(100%+5px)] w-full object-cover"
                    alt="background"
                />
            </div>
            <div className=" mt-0">
                <StaticTestimonial
                    title={"Thoughts from Ski.com"}
                    content={
                        <p>
                            Working with Saigon Digital was a game-changer for
                            Ski.com. They helped us build a new website in
                            record time, leveraging the power and flexibility of
                            the Prismic CMS. On top of that, they tackled the
                            complex task of transferring data from our existing
                            proprietary CMS while seamlessly interfacing with
                            our development team to ensure everything was
                            implemented flawlessly. Their expertise,
                            responsiveness, and commitment to our project made
                            the entire process seamless. Not only did they
                            deliver a site that exceeded our expectations in
                            terms of performance and design, but they also
                            ensured it was easy to manage and scale for future
                            needs. We couldn’t have asked for a better partner
                            to bring our vision to life. Thank you, Saigon
                            Digital, for your exceptional work!
                        </p>
                    }
                    author={"Harry Peisach"}
                    position={"CEO Ski.com"}
                />
            </div>
            <ShowCase data={caseStudies} />
        </Layout>
    )
}

const slides = [
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/ski/Ski.com-slide-1.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    },
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/ski/Ski.com-slide-2.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    },
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/ski/Ski.com-slide-3.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    },
    {
        component: (
            <div className=" aspect-[1440/360] h-auto w-full  ">
                <img
                    src="/case-study/ski/Ski.com-slide-4.png"
                    alt="shape-1 "
                    className="absolute inset-0  h-full w-full object-cover"
                />
            </div>
        )
    }
]

export default SkiCom

export const Head = () => (
    <SEO
        title={"Ski.com | Saigon Digital Case Study"}
        description={
            "Discover how Saigon Digital modernized Ski.com’s website with sleek design, enhanced SEO, and seamless booking integration, positioning them as a digital leader."
        }
        keywords={
            "web design saigon, web development saigon, jamstack development, jamstack web development saigon"
        }
        image="/case-study/ski/og-image.png"
    />
)
